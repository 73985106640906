<template>
  <mp-popover id="unified-notifications-popover" placement="bottom">
    <mp-popover-trigger>
      <mp-box position="relative">
        <mp-button-icon name="notification" size="md" />
        <mp-badge
          v-if="inboxNotification.length"
          variant-color="red"
          size="sm"
          position="absolute"
          top="0"
          right="0"
          border-width="2px"
          border-color="white"
        >
          {{ inboxNotification.length }}
        </mp-badge>
      </mp-box>
    </mp-popover-trigger>
    <mp-popover-content
      class="popover-content"
      :width="{ xl: 'sm', lg: 'sm', md: 'sm', sm: 'sm', xs: 'sm' }"
      max-height="calc(100% - 96px)"
      overflow="hidden auto"
      bg="white"
      rounded="md"
      shadow="lg"
      border-width="1px"
      border-color="gray.400"
      mr="6 !important"
      z-index="popover"
    >
      <mp-flex ref="notificationTitle" px="4" py="3">
        <mp-text font-size="lg" font-weight="semibold">Notifications</mp-text>
      </mp-flex>

      <mp-flex>
        <mp-tabs
          id="unified-notifications-tabs"
          :index="tabIndex"
          width="full"
          is-manual
          @change.self="handleChangTab"
        >
          <mp-tab-list
            position="sticky"
            mb="0"
            px="4"
            top="0"
            bg="white"
            z-index="1"
          >
            <mp-tab>
              Inbox
              <mp-badge
                size="md"
                ml="2"
                line-height="normal"
                :variant-color="tabIndex === 0 ? 'red' : 'gray'"
              >
                {{ incoming_notification.inbox.length }}
              </mp-badge>
            </mp-tab>
          </mp-tab-list>
          <mp-tab-panels pb="4">
            <mp-tab-panel>
              <mp-box>
                <mp-flex pt="4" direction="column">
                  <mp-box
                    v-for="(item, index) in inboxNotification"
                    :key="`inbox-${index}`"
                  >
                    <mp-text
                      v-if="item.displayDivider"
                      mb="2"
                      px="4"
                      font-size="sm"
                      color="gray.600"
                    >
                      {{ item.dateCondition }}
                    </mp-text>
                    <mp-flex
                      :key="`inbox-data-${index}`"
                      px="4"
                      py="3"
                      gap="16px"
                      :_hover="{
                        backgroundColor: 'gray.50',
                        cursor: 'pointer',
                      }"
                      @click="
                        pushToRoom(item.link.id, item.channel, item.unreadCount)
                      "
                    >
                      <mp-icon
                        mt="1"
                        :name="getMessageTypeIcon(item.messageType)"
                      />
                      <mp-flex direction="column" width="390px">
                        <mp-text
                          v-dompurify-html="item.link.name"
                          font-weight="semibold"
                          font-size="lg"
                        ></mp-text>
                        <mp-text
                          v-dompurify-html="sliceWords(item.title, 100)"
                          mb="1"
                        />
                        <mp-text font-size="sm" color="gray.600">
                          {{ $moment(item.date).format('DD MMMM YYYY') }} at
                          {{ $moment(item.date).format('HH:mm') }} (GMT+7)
                        </mp-text>
                      </mp-flex>
                      <mp-flex>
                        <mp-tooltip
                          v-if="item.isNew"
                          :id="`unified-notifications-inbox-tooltip-${index}`"
                          label="Mark as read"
                          position="left"
                        >
                          <mp-icon name="indicator-circle" color="red.400" />
                        </mp-tooltip>
                      </mp-flex>
                    </mp-flex>
                  </mp-box>
                </mp-flex>
                <mp-box v-if="inboxNotification.length < 1" align="center">
                  <mp-text mt="5" color="gray.600"
                    >You do not have any notifications yet.</mp-text
                  >
                </mp-box>
              </mp-box>
            </mp-tab-panel>
          </mp-tab-panels>
        </mp-tabs>
      </mp-flex>
    </mp-popover-content>
  </mp-popover>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'LayoutsMainNotification',
  components: {},
  data() {
    return {
      isHeaderSticky: false,
      tabIndex: 0,
      generalFilter: 'inbox',
    }
  },
  computed: {
    ...mapState('layouts', ['incoming_notification']),
    inboxNotification() {
      const notifications = this.incoming_notification.inbox
      const formattedNotifications = []
      for (const x in notifications) {
        const data = {
          title: notifications[x].message.text,
          date: notifications[x].created_at,
          avatar: null,
          link: notifications[x].room,
          type: 'inbox',
          isNew: false,
          hasMenu: false,
          displayDivider: false,
          dateCondition: 'Today',
          messageType: notifications[x].message_type,
          channel: notifications[x].room.channel,
          unreadCount: notifications[x].room.unreadCount,
        }

        if (x < 1) {
          data.displayDivider = true
        } else if (x > 0) {
          if (
            moment(notifications[x].created_at).format('D') !==
            moment(notifications[x - 1].created_at).format('D')
          )
            data.displayDivider = true
          else data.displayDivider = false
        }

        // Grouping By Day (Detail)
        if (
          moment(notifications[x].created_at).format('D') ===
          moment().format('D')
        )
          data.dateCondition = 'Today'
        else if (
          moment(notifications[x].created_at).format('D') ===
          moment().subtract(1, 'days').format('D')
        )
          data.dateCondition = 'Yesterday'
        else
          data.dateCondition = moment(notifications[x].created_at).format(
            'dddd, DD MMMM YYYY'
          )

        formattedNotifications.push(data)
      }
      return formattedNotifications
    },
  },
  mounted() {
    this.handleSticky()
  },
  methods: {
    async pushToRoom(id, channel, unreadCount = 0) {
      delete this.$route.query.type
      this.$nuxt.$emit('setDefaultRoomInformation')
      this.$store.commit('conversation/UPDATE_QUERY_ROOM', id)
      if (id !== this.$route.query.room) {
        this.$store.commit('conversation/SET_INITIAL_CONDITION')
        this.$store.commit('conversation/UPDATE_UNREAD_IN_ROOM', unreadCount)
        this.$store.commit('conversation/SET_CONTACT_DETAIL_DATA', null)
        this.$store.commit('conversation/UPDATE_ACTIVE_ROOM_DATA', [])
      }
      this.$store.dispatch('conversation/getParticipantLists', { id })
      if (channel !== 'ig_comment') {
        await this.$nuxt.$emit('onSetFilterRoomTabs', 'messages')
        this.$router.push({
          name: 'inbox',
          query: { ...this.$route.query, type: '', room: id },
        })
      } else {
        await this.$nuxt.$emit('onSetFilterRoomTabs', 'comments')
        this.$router.push({
          name: 'inbox',
          query: { ...this.$route.query, type: 'comments', room: id },
        })
      }
    },
    handleChangTab(index) {
      this.tabIndex = index
    },
    showAlert(message) {
      alert(message)
    },
    handleSticky() {
      const el = this.$refs.notificationTitle.$el
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting === false) {
            this.isHeaderSticky = true
          } else {
            this.isHeaderSticky = false
          }
        },
        { threshold: [0] }
      )

      observer.observe(el)
    },
    getMessageTypeIcon(msgType) {
      switch (msgType) {
        case 'handover':
          return 'people'
        case 'add_agent':
          return 'people'
        default:
          return 'chat'
      }
    },
  },
}
</script>

<style>
/* custom scroll bar */
.popover-content::-webkit-scrollbar {
  width: 0;
}

.popover-content::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.popover-content:hover::-webkit-scrollbar {
  width: 5px;
}

.popover-content:hover::-webkit-scrollbar-thumb {
  background: var(--colors-gray-400);
}
</style>
