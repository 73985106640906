<template>
  <ThemeProvider>
    <mp-box overflow="hidden">
      <MpReset />
      <slot />
    </mp-box>
  </ThemeProvider>
</template>

<script>
import { ThemeProvider, MpReset } from '@mekari/pixel'

export default {
  name: 'PixelWrapper',
  components: {
    ThemeProvider,
    MpReset,
  },
}
</script>
